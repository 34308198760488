<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Income
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Income</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_income')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'addNewIncome',
              mode: 'create',
            })
          "
        >
          New Income
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0" @change="searchFilter">
          <select v-model="params.pageIndex" id="income_filter_size" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" id="income_filter_branch_type" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="branch in incomeElements.filter_branchs"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select v-model="params.type" class="filter-input mr-2 mg-t-5" id="income_filter_income_type" @change="searchFilter">
            <option value="">All Income Type</option>
            <option
              v-for="type in incomeElements.account_types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.title }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="params.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              id="income_filter_date_from"
              @change="getFilteredDataWithoutDates"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="params.to_date"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              id="income_filter_date_to"
              @change="getFilteredDataWithoutDates"
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            id="income_filter_search"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Income Id</th>
              <th class="wd-20p">Account Name</th>
              <th class="wd-15p">Payment Mode</th>
              <th class="wd-10p">Amount</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Due Amt.</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_income','edit_income','cancel_income'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && incomeLists.length > 0">
            <tr v-for="(income, index) in incomeLists" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{ income.date }}</td>
              <td>#{{(income.income_id) }} <span v-if="income.status == 1" class="tx-danger">-Canceled</span></td>
              <td>{{ income.account.account_name }}</td>
              <td>{{
                  income.payment_method.title
                }}
                <span v-if="income.bank_id">-{{income.bank.bank}}</span>
              </td>
              <td>{{ parseDigit(income.income_amount) }}</td>
              <td>{{ parseDigit(income.paid_amount)}}</td>
              <td>{{ parseDigit(income.due_amount) }}</td>
              <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_income','edit_income','cancel_income'])">
                <a href="javascript:;" class="mr-3" :id="'incomeView'+income.id" v-if="checkSinglePermission('view_income')"> 
                  <i
                    class="fas fa-eye tx-success"   
                    title="View"
                    @click="
                      $store.commit('setDataId', income.id),
                        $store.dispatch('modalOpen', 'receiptSlip')
                    "
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  :id="'incomeEdit'+income.id"
                  title="Edit"
                  @click="edit('editIncome', income.id)"
                 v-if="income.status == 0 && checkSinglePermission('edit_income')">
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" :id="'incomeCancel'+income.id" title="Cancel" @click="cancel(income.id)"  v-if="income.status == 0 && checkSinglePermission('cancel_income')">
                  <i class="fas fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
            <td colspan="11" class="text-center">
              {{error_message}}
            </td>
          </tbody>
          <tbody v-else-if="!loading && incomeLists.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
         <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <IncomeCreate @parent-event="getData()" />
        <IncomeEdit @parent-event="getData()" />
        <Slip />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import IncomeCreate from "./create";
import IncomeEdit from "./edit";
import Slip from "./Slip";
import Paginate from 'vuejs-paginate';
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  components: {
    IncomeCreate,
    IncomeEdit,
    Slip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date: '',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      error_message:'',
      page: 1,
    };
  },
   computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("incomes", ["incomeLists", "incomeElements"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
      clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    searchKeyword:_.debounce(function(){
        this.page= 1;
        this.pageSerialNo = 1;
        this.params.offset = 0;
        this.getFilteredDataWithoutDates();
    },1000),
    searchFilter(){
        this.page= 1;
        this.pageSerialNo = 1;
        this.params.offset = 0;
        this.getFilteredDataWithoutDates();
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getIncomeList(params)
        .then((res) => {
          this.$store.commit("incomes/setIncomeLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
           if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.error = true;
          this.$store.commit("incomes/setIncomeLists", []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Income ?",
        html: `<label>Remarks: </label>
                <textarea id="cancel-remarks" class="swal2-input"></textarea>
            `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
          return Services.cancelIncome(id, { remarks: remarks })
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    getData() {
      //this.$store.commit("getData", `api/incomes/size/${this.filter.size}`);
      this.getFilteredDataWithoutDates();
      this.getElements();
    },
    getElements() {
      Services.getIncomeElements()
        .then((res) => {
          this.$store.commit("incomes/setIncomeElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
</style>