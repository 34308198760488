<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='addNewIncome'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Income</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Income Type</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                v-model="formData.type_id"
                                type="radio"
                                name="income_type"
                                id="direct_income"
                                :value="incomeElements.direct_income_id"
                                @change="accountHeads()"
                                required
                              />
                              <label for="direct_income" class="ml-2">Direct Income</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                v-model="formData.type_id"
                                name="income_type"
                                id="indirect_income"
                                :value="incomeElements.indirect_income_id"
                                @change="accountHeads()"
                                required
                              />
                              <label for="indirect_income" class="ml-2">Indirect Income</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select class="form-control" id="income_branch_name" v-model="formData.branch_id" required :disabled="branchDisabled" @change="getPayments()">
                              <option value selected disabled>Select Branch</option>
                              <option
                                v-for="(branch,index) in incomeElements.branches"
                                :key="index"
                                :value="branch.id"
                              >{{ branch.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Income ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.income_id"
                              class="form-control"
                              id="income_id"
                              placeholder="id"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              id="income_date"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Account Head *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select class="form-control" id="income_account_head" v-model="formData.account_id" title="Please Select Income Type First" required>
                              <option value selected disabled>Account Head</option>
                              <option
                                v-for="(account,index) in account_heads"
                                :key="index"
                                :value="account.id"
                              >{{account.account_name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Customer</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select class="form-control" id="income_customer" v-model="formData.customer_id" @change="customerChange()">
                              <option value="">Select Customer</option>
                              <option v-for="(customer,index) in incomeElements.customers" :value="customer.id" :key="index">{{customer.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              v-model="formData.description"
                              id="income_description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Income Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              min="1"
                              v-model="formData.income_amount"
                              class="form-control"
                              id="income_amount"
                              placeholder="0.00"
                              @change="calculateDue()"
                              @keyup="calculateDue()"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              min="0"
                              v-model="formData.paid_amount"
                              class="form-control"
                              id="income_paid_amount"
                              placeholder="0.00"
                              :max="formData.income_amount"
                              :disabled="disabledPaidAmount"
                              @change="calculateDue()"
                              @keyup="calculateDue()"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.due_amount"
                              class="form-control"
                              id="income_remaining_amount"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.payment_method_id" class="form-control" id="income_payment_method" @change="getBanks()">
                              <option value="" disabled>Select Method</option>
                              <option
                                v-for="(method,index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >{{ method.title }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank_id"
                              class="form-control"
                              id="income_bank_name"
                              :disabled="bank_disabled"
                            >
                                <option value="" disabled>Select Bank</option>
                              <option
                                v-for="(bank,index) in banks"
                                :key="index"
                                :value="bank.id"
                              >{{ bank.bank }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.transaction_id"
                              class="form-control"
                              id="income_transaction_id"
                              placeholder="Payment Ref."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-right mt-4 pt-3 pr-0">
                      <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{(disableSubmitButton)?"Submitting...":"Submit"}}</button>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      account_heads: [],
      formData: {
        id: "",
        branch_id: "",
        income_id: null,
        date: this.today,
        account_id: "",
        payment_method_id: "",
        customer_id:"",
        bank_id: "",
        transaction_id: null,
        type_id: "",
        income_amount: null,
        paid_amount: null,
        due_amount: null,
        description: null,
      },
      disabledPaidAmount:true,
      disableSubmitButton:false,
      bank_disabled:true,
      payement_methods:[],
      banks:[],
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("incomes", ["incomeLists", "incomeElements"]),
  },
  mounted() {
    this.formData.date = this.today;
  },
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.createNewIncome(this.formData)
        .then((res) => {
          this.$store.commit("incomes/setIncomeLists", res.data.data.data);
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "afterCreateSlip",
            mode: "read",
          });
          this.$emit("parent-event");
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    getPayments() {
        this.getIncomeId();
        this.bank_disabled = true;
        this.formData.bank_id = "";
        if (this.incomeElements.payment_methods.length != 0) {
          let details = [];
          let methods = this.formData.branch_id;
          details = this.incomeElements.payment_methods.filter(function (data) {
            return data.branch == methods;
          });
          this.payement_methods = { ...details };
          this.formData.payment_method_id = this.payement_methods[0].id;
        }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.incomeElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.incomeElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.incomeElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
    getIncomeId(){
        Services.getIncomeId()
        .then((res) => {
          this.formData.income_id = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    customerChange(){
        if(this.formData.customer_id == ""){
            this.disabledPaidAmount = true;
            this.formData.paid_amount = this.formData.income_amount ;
        }else{
            this.disabledPaidAmount = false;
        }
        this.calculateDue()
    },
    calculateDue() {
      if(this.formData.customer_id != ""){
          this.formData.due_amount = this.parseDigit(this.formData.income_amount - this.formData.paid_amount);
      }
      else{
          this.formData.paid_amount = this.formData.income_amount;
          this.formData.due_amount =this.parseDigit( this.formData.income_amount - this.formData.paid_amount);
      }
    },
    accountHeads() {
      if (this.incomeElements.accounts.length != 0) {
        this.formData.account_id="";
        let account_type = this.formData.type_id;
        let details = this.incomeElements.accounts.filter(function (data) {
          return data.account_type == account_type;
        });
        this.account_heads = { ...details };
      }
    },
    clearData(){
        this.account_heads= [];
        this.formData.id= "";
        this.formData.branch_id= "";
        this.formData.income_id= null;
        this.formData.date= this.today;
        this.formData.account_id= "";
        this.formData.payment_method_id= "";
        this.formData.customer_id="";
        this.formData.bank_id= "";
        this.formData.transaction_id= null;
        this.formData.type_id= "";
        this.formData.income_amount= null;
        this.formData.paid_amount= null;
        this.formData.due_amount= null;
        this.formData.description= null;
        this.disabledPaidAmount=true;
        this.disableSubmitButton=false;
        this.bank_disabled=true;
        this.payement_methods=[];
        this.banks=[];
    },
  },
  watch: {
    modalId(value) {
      if (value == "addNewIncome") {
        this.clearData();
        this.getIncomeId();
        Services.getIncomeElements()
          .then((res) => {
            this.$store.commit("incomes/setIncomeElements", res.data.data);
            if(res.data.data.branches.length == 1)
            {
              this.formData.branch_id = res.data.data.branches[0].id;
              this.branchDisabled = true;
              this.getPayments();
              this.getExpenseIdByBranch();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
