import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class IncomeService{
    getIncomeList(param){
        const url = `api/incomes/filter`
        const params = {
            'page-index': param.pageIndex,
            'branch': param.branch,
            'today': param.today,
            'month': param.month,
            'year': param.year,
            'from': param.from_date,
            'type':param.type,
            'to': param.to_date,
            'searched': param.searched,
            'offset': param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getIncomeElements(){
        const url = `api/income/create`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewIncome(formdata){
        const url = `api/income/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    cancelIncome(id,formdata){
        const url = `api/incomes/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    updateIncome(id,formdata){
        const url = `api/income/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    getIncomeId(){
        const url = `api/incomes/id`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

}
export default new IncomeService();